var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("shopping-cart-body", {
        attrs: { parentCartBody: _vm.response, urlImage: _vm.urlImage },
        on: { clicked: _vm.onUpdateChild }
      }),
      _vm._v(" "),
      _c("shopping-cart-footer", {
        attrs: { parentCartFooter: _vm.response, infoDetail: _vm.info },
        on: { clicked: _vm.onUpdateChild }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }