<template>
  <div>
    <shopping-cart-body @clicked="onUpdateChild" :parentCartBody="response" :urlImage="urlImage"></shopping-cart-body>
    <shopping-cart-footer @clicked="onUpdateChild" :parentCartFooter="response"
                          :infoDetail="info"></shopping-cart-footer>
  </div>
</template>

<script>
import SCBody from './ShoppingCartBody';
import SCFooter from './ShoppingCartFooter';

export default {
  props: {
    info: Object,
  },
  components: {
    'shopping-cart-body': SCBody,
    'shopping-cart-footer': SCFooter,
  },
  data() {
    return {
      response: [],
      urlImage: `/images/no_image_dormak.jpg`
    }
  },
  methods: {
    onUpdateChild(value) {
      this.response = value;

      let counterShoppingCart = document.getElementById('shopingbag-icon-page').querySelectorAll('span.number')[0];
      counterShoppingCart.textContent = value.items_count;
    }
  },
  mounted() {
    //console.log('montou o componente');
  },
  created() {
    let cart = localStorage.getItem('cartService');
    if (cart != null) {
      cart = JSON.parse(cart);
      this.response = cart;
    }
    window.cart.get((response) => {
      this.response = response;
    })
  },
}
</script>
