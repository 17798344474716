var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-footer" }, [
    _c("h6", { staticClass: "detail-title" }, [
      _vm._v(_vm._s(_vm.titleFooter))
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        {
          staticClass:
            " col-12 col-lg-5 offset-lg-1 col-xl-4 offset-xl-2 order-lg-2"
        },
        [
          _c("div", { staticClass: "detail-total-purchase" }, [
            _c("div", { staticClass: "first-block" }, [
              _c("span", { staticClass: "quantityProducts" }, [
                _vm._v(
                  _vm._s(_vm.parentCartFooter.items_count) +
                    " " +
                    _vm._s(_vm.wordProduct)
                )
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "subtotalPriceProducts" }, [
                _vm._v(
                  _vm._s(
                    _vm._f("formatNumber")(
                      _vm.parentCartFooter.original_total_price
                    )
                  )
                )
              ]),
              _vm._v(" "),
              _c("span", [_vm._v(" " + _vm._s(_vm.wordDiscount) + " ")]),
              _vm._v(" "),
              _c("span", { staticClass: "discountPriceProducts" }, [
                _vm._v(
                  _vm._s(
                    _vm._f("formatNumber")(
                      _vm.calculateDiscount(_vm.parentCartFooter.items)
                    )
                  )
                )
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "discountPriceProducts" }, [
                _vm._v("IVA")
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "discountPriceProducts" }, [
                _vm._v(
                  _vm._s(
                    _vm._f("formatNumber")(_vm.parentCartFooter.total_vat_price)
                  )
                )
              ])
            ]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("div", { staticClass: "second-block" }, [
              _c("span", [_vm._v(_vm._s(_vm.wordTotal))]),
              _vm._v(" "),
              _c("span", { staticClass: "totalPriceProducts" }, [
                _vm._v(
                  _vm._s(
                    _vm._f("formatNumber")(
                      _vm.parentCartFooter.total_price +
                        _vm.parentCartFooter.total_vat_price
                    )
                  )
                )
              ])
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-lg-6 order-lg-1 block-cupons" }, [
        _c("label", { staticClass: "cupon-title" }, [
          _vm._v(" " + _vm._s(_vm.wordCouponCode) + " ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "promo" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.code,
                expression: "code"
              }
            ],
            staticClass: "promotional-code-input",
            attrs: { type: "text", placeholder: _vm.wordEnterCouponCode },
            domProps: { value: _vm.code },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.code = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "promotional-code-application",
              attrs: { type: "submit", disabled: _vm.isDisableFixed(_vm.code) },
              on: {
                click: function($event) {
                  _vm.redeemCode(_vm.code)
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.wordApply) + "\n        ")]
          )
        ]),
        _vm._v(" "),
        _c("label", { staticClass: "cupon-title" }, [
          _vm._v(" Códigos de Cupão Aplicados ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "promo" }, [
          _c(
            "ul",
            _vm._l(_vm.parentCartFooter.discount_applications, function(
              discountAplication
            ) {
              return !discountAplication.isAutoApply
                ? _c("li", [
                    _vm._v(
                      " " +
                        _vm._s(discountAplication.redeemCode) +
                        "\n          "
                    )
                  ])
                : _vm._e()
            })
          )
        ]),
        _vm._v(" "),
        _c("label", { staticClass: "cupon-title" }, [
          _vm._v(" " + _vm._s(_vm.infoDetail.title) + " ")
        ]),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("div", {
          staticClass: "txt-shipping-method",
          domProps: { innerHTML: _vm._s(_vm.infoDetail.content) }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "actions-to-continue" }, [
      _c("span", { staticClass: "next-step", on: { click: _vm.checkout } }, [
        _vm._v(_vm._s(_vm.wordCheckout))
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }