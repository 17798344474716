<template>
  <div class="page-body">
    <div class="table-responsive">
      <table class="list-of-chosen-products">
        <thead>
          <tr>
            <th v-for="title in headTable" v-bind:title="title">
              {{title}}
            </th>
          </tr>
        </thead>
        <tbody v-for="line in parentCartBody.items" v-if="!line.isOffer">
          <tr>
            <td class="info-product">
              <a v-bind:href="line.url" target="_blank">
                <img class="product-image" :src="line.image ? line.image.thumbnail_80x80 : urlImage">
              </a>
              <div class="product-desc-block">
                <a v-bind:href="line.url" target="_blank">
                  <h1 class="product-description">{{ line.name }}</h1>
                </a>
                <h1 class="breadcrumb-product">{{ line.url }}</h1>
              </div><!-- .product-desc-block -->
            </td>
            <td>
              <div v-if="line.discount != 0">
                <h1 class="product-discount-price">{{ (line.original_price * (1+line.product.iva)) | formatNumber }} </h1>
                <h1 class="product-price">{{ (line.final_price * (1+line.product.iva) )| formatNumber }} </h1>
              </div>
              <div v-else>
                <h1 class="product-price">{{ (line.original_price * (1+line.product.iva)) | formatNumber }} </h1>
              </div>
            </td>
            <td>
              <div class="counter-click">
                <button @click="decrease(line.id)" >-</button>
                <input @blur="inputUpdateProduct(line.id)" v-model="line.quantity" type="number">
                <button @click="increase(line.id)" >+</button>
              </div>
            </td>
            <td>
              <div v-if="line.discount != 0">
                <h1 class="discounted-full-price">{{ (line.original_line_price * (1+line.product.iva))| formatNumber }}</h1>
                <h1 class="product-total">{{ (line.final_line_price * (1+line.product.iva))| formatNumber }}</h1>
              </div>
              <div v-else>
                <h1 class="product-total">{{ (line.original_line_price * (1+line.product.iva)) | formatNumber }}</h1>
              </div>
            </td>
            <td class="actions-to-user">
              <span class="btn-remove-item" v-bind:class="[line.isOffer ? 'd-none' : '']" @click="deleteProduct(line.id)">
                <i class="far fa-trash-alt"></i>
              </span>
            </td>
            <td></td>
          </tr>
          <tr v-for="offer in parentCartBody.items" v-if="offer.isOffer && offer.referredLineItem.id == line.id ">
            <td class="info-product offer-product-line">
              <a v-bind:href="offer.url" target="_blank">
                <img class="product-image" :src="offer.image ? offer.image.thumbnail_80x80 : urlImage">
              </a>
              <div class="product-desc-block">
                <a v-bind:href="offer.url" target="_blank">
                  <h1 class="product-description"><b>( OFERTA )</b> <br/>{{ offer.name }}</h1>
                </a>
                <h1 class="breadcrumb-product">{{ offer.url }}</h1>
              </div><!-- .product-desc-block -->
            </td>
            <td>
              <div v-if="offer.discount != 0">
                <h1 class="product-discount-price">{{ offer.original_price | formatNumber }} </h1>
                <h1 class="product-price">{{ offer.final_price | formatNumber }} </h1>
              </div>
              <div v-else>
                <h1 class="product-price">{{ offer.original_price | formatNumber }} </h1>
              </div>
            </td>
            <td>
              <div class="counter-click">
                <button @click="decrease(offer.id)" >-</button>
                <input @blur="inputUpdateProduct(offer.id)" v-model="offer.quantity" type="number">
                <button @click="increase(offer.id)" >+</button>
              </div>
            </td>
            <td>
              <div v-if="offer.discount != 0">
                <h1 class="discounted-full-price">{{ offer.original_line_price| formatNumber }}</h1>
                <h1 class="product-total">{{ offer.final_line_price | formatNumber }}</h1>
              </div>
              <div v-else>
                <h1 class="product-total">{{ offer.original_line_price | formatNumber }}</h1>
              </div>
            </td>
            <td class="actions-to-user">
              <span class="btn-remove-item" v-bind:class="[offer.isOffer ? 'd-none' : '']" @click="deleteProduct(offer.id)">
                <i class="far fa-trash-alt"></i>
              </span>
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div><!-- .page-body -->
</template>

<script>
  export default {
    props: {
      parentCartBody: Object,
      urlImage: String
    },
    data() {
      return {
        headTable: [
          trans('frontend.Products'),
          trans('frontend.price'),
          trans('frontend.quantity'),
          trans('frontend.total')
        ],
      }
    },
    methods: {
      increase(id){
        const _self = this;
        _self.updateProduct(1, id);
      },
      decrease(id){
        const _self = this;
        _self.updateProduct(-1, id);
      },
      updateProduct(newValue, idProduct){
        const _self = this;
        window.cart.update({
          "items" : [
            {
              "id" : idProduct,
              "quantity" : (newValue * 1),
              "add" : 1
            }
          ]
        },(response)=>{
          _self.$emit('clicked', response);
        });
      },
      inputUpdateProduct(idProduct){
        const _self = this;
        window.cart.update({
          "items" : [
            {
              "id" : idProduct,
              "quantity" : event.target.value,
            }
          ]
        },(response)=>{
          _self.$emit('clicked', response);
        });
      },
      deleteProduct(idProduct){
        const _self = this;
        window.cart.update({
          "items" : [
            {
              "id" : idProduct,
              "quantity" : 0,
            }
          ]
        }, (response)=>{
          _self.$emit('clicked', response);
        });
      }
    },
    mounted(){
      //console.log('montou o componente body');
    },
  }
</script>
