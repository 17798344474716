<template>
  <div class="page-footer">
    <h6 class="detail-title">{{ titleFooter }}</h6>

    <div class="row">
      <div class=" col-12 col-lg-5 offset-lg-1 col-xl-4 offset-xl-2 order-lg-2">
        <div class="detail-total-purchase">
          <div class="first-block">
            <span class="quantityProducts">{{ parentCartFooter.items_count }} {{ wordProduct }}</span>
            <span class="subtotalPriceProducts">{{ parentCartFooter.original_total_price | formatNumber }}</span>
            <span> {{ wordDiscount }} </span>
            <span class="discountPriceProducts">{{ calculateDiscount(parentCartFooter.items) | formatNumber }}</span>
            <span class="discountPriceProducts">IVA</span>
            <span class="discountPriceProducts">{{ parentCartFooter.total_vat_price | formatNumber }}</span>
          </div>
          <hr>
          <div class="second-block">
            <span>{{ wordTotal }}</span>
            <span class="totalPriceProducts">{{ (parentCartFooter.total_price + parentCartFooter.total_vat_price) | formatNumber }}</span>
          </div>
        </div><!-- .detail-total-purchase -->
      </div> <!-- .col-6 -->

      <div class="col-12 col-lg-6 order-lg-1 block-cupons">
        <label class="cupon-title"> {{ wordCouponCode }} </label>
        <div class="promo">
          <input class="promotional-code-input" type="text" v-bind:placeholder="wordEnterCouponCode" v-model="code">
          <button class="promotional-code-application" type="submit" v-bind:disabled="isDisableFixed(code)"
                  @click="redeemCode(code)"> {{ wordApply }}
          </button>
        </div><!-- .promo -->
        <label class="cupon-title"> Códigos de Cupão Aplicados </label>
        <div class="promo">
          <ul>
            <li v-for="discountAplication in parentCartFooter.discount_applications"
                v-if="!discountAplication.isAutoApply"> {{ discountAplication.redeemCode }}
            </li>
          </ul>
        </div><!-- .promo -->

        <label class="cupon-title"> {{ infoDetail.title }} </label>
        <br>
        <div class="txt-shipping-method" v-html="infoDetail.content"></div>
      </div> <!-- .block-cupons -->
    </div><!-- .row -->

    <div class="actions-to-continue">
      <span @click="checkout" class="next-step">{{ wordCheckout }}</span>
      <!-- a href="#"  class="next-step" disabled>{{ wordCheckout }}</a -->

    </div><!-- .actions-to-continue -->

  </div><!-- .page-footer -->
</template>

<script>


export default {
  props: {
    parentCartFooter: Object,
    infoDetail: Object,
  },
  data() {
    return {
      titleFooter: trans('frontend.summary'),
      wordProduct: trans('frontend.Products_sem_iva'),
      wordDiscount: trans('frontend.discount'),
      wordTotal: trans('frontend.total'),
      wordCouponCode: trans('frontend.coupon-code'),
      wordEnterCouponCode: trans('frontend.enter-coupon-code'),
      wordApply: trans('frontend.apply'),
      wordShippingMethod: trans('frontend.shipping-method'),
      wordCheckout: trans('frontend.checkout'),
      code: '',
      route: '',
      phraseAboutPurchase: trans('frontend.phrase-about-purchase'),
      phraseAboutPriceAtHome: trans('frontend.phrase-about-price-at-home'),
      phraseAboutPriceAtHome1: trans('frontend.phrase-about-price-at-home1'),
      phraseAboutPriceAtHome2: trans('frontend.phrase-about-price-at-home2'),
      phraseAboutPriceAtHome3: trans('frontend.phrase-about-price-at-home3'),
      phraseAboutPriceAtShop: trans('frontend.phrase-about-price-at-shop'),
      phraseAboutPriceAtShop1: trans('frontend.phrase-about-price-at-shop-1'),
      phraseAboutPriceAtShop2: trans('frontend.phrase-about-price-at-shop-2'),
    };
  },
  mounted() {
    //console.log('montou o componente footer');
  },
  created() {
  },
  computed: {
    isDisableComputed() {
      return this.code.length == 0;
    },

  },
  methods: {
    calculateDiscount(items) {
      let TotaldDiscount = 0;
      for (let i = 0; i < items.length; i++) {
        const lineItem = items[i];
        const precoOriginalLinhaIva = (lineItem.original_line_price * (1+ lineItem.product.iva));
        const precoFinalLinhaIva = (lineItem.final_line_price * (1+ lineItem.product.iva));
        TotaldDiscount += (precoOriginalLinhaIva - precoFinalLinhaIva);
      }
      return TotaldDiscount;
    },
    capitalize(s) {
      if (typeof s !== 'string') return ''
      return s.charAt(0).toUpperCase() + s.slice(1)
    },
    checkout() {

      window.cart.checkout({},
        (response) => {
          //Direciona para o finalizar de compra
          window.location.href = `/${window.laguage}/finalizar-compra`;
        }, (response) => {

          let dangerAlert = document.getElementById('app-shopping-cart').querySelectorAll('.shopping-cart-alert ')[0];
          let dangerAlertMsg = document.getElementById('app-shopping-cart').querySelectorAll('.shopping-cart-alert .text')[0];
          dangerAlertMsg.innerHTML = '';

          if (response.message === 'Your email address is not verified.') {
            dangerAlertMsg.innerHTML = trans('frontend.Your-email-address-is-not-verified');
          } else {
            dangerAlertMsg.innerHTML = trans('frontend.An-error-occurred,-please-try-again-later');
          }

          dangerAlert.classList.remove('d-none');
          dangerAlert.style.zIndex = '1';

          setTimeout(() => {
            dangerAlert.classList.add('d-none');
            dangerAlert.style.zIndex = '-1';
          }, 6000);
        });
    },
    redeemCode(code) {
      console.log(code);
      const _self = this;
      window.cart.update({
        "discount_applications": code,

      }, (response) => {
        _self.$emit('clicked', response);
      });
    },
    isDisableFixed(code) {
      return code.length == 0;
    }
  },
}
</script>

