/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
import ShoppingCart from './components/ShoppingCart.vue';

window.$ = window.jQuery = require('jquery');
require('../bootstrap');
require('../plugins');
require('jquery-migrate');
require('slick-carousel');
require('datatables.net');
require('jquery-lazy');

window.Vue = require('vue');

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

//Vue.component('example-component', require('./components/ExampleComponent.vue').default);
//Vue.component('shopping-cart', require('./components/ShoppingCart.vue').default);

//Vue.component('shopping-cart-header', require('./components/ShoppingCartHeader.vue').default);
/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */


var numeral = require("numeral");

if (numeral.locales['user-locale'] === undefined) {
  numeral.register('locale', 'user-locale', {
    delimiters: {
      thousands: '.',
      decimal: ','
    },
    abbreviations: {
      thousand: 'k',
      million: 'm',
      billion: 'b',
      trillion: 't'
    },
    currency: {
      symbol: '€'
    }
  });
}

Vue.filter("formatNumber", function (value) {
  var locale = 'de';
  var options = {style: 'currency', currency: 'eur', minimumFractionDigits: 2, maximumFractionDigits: 2};
  var formatter = new Intl.NumberFormat(locale, options);

  return formatter.format(value); // displaying other groupings/separators is possible, look at the docs
});
